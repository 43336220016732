const artistsData = {
  biancacosta: {
    name: "Bianca Costa",
    url: "biancacosta",
    formID: "38817",
    startDate: "",
    pathName: "biancacosta",
    dota: "une invitation à l'aftershow du concert à La Cigale en compagnie de Bianca Costa et de son équipe",
    dotaTwi:
      "une invitation à l'aftershow du concert à La Cigale en compagnie de Bianca Costa et de son équipe !",
    categories: [
      {
        name: "Révélation de l'année",
        link: "https://BiancaCosta.lnk.to/nma",
        id: "bianca-costa-revelation-de-l-annee",
      },
    ],
    picture: "/assets/img/biancacosta-desktop.png",
    pictureMobile: "/assets/img/biancacosta-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/biancacosta",
        points: 20,
      },
      {
        social: "clip",
        twitterText: "Découvrez le titre « Olé Olé » de Bianca Costa",
        text: "Partage le titre « Olé Olé » sur ton réseau social favori",
        url: "BiancaCosta.lnk.to/oleole",
        points: 20,
      },
    ],
  },
  christophemae: {
    name: "Christophe Maé",
    url: "christophemae",
    formID: "38818",
    startDate: "",
    pathName: "christophemae",
    dota: '2 places de concert et 1 édition collector de "C\'est drôle la vie" dédicacée',
    dotaTwi:
      '2 places de concert et 1 édition collector de "C\'est drôle la vie" dédicacée !',
    categories: [
      {
        name: "Tournée Francophone",
        link: "https://ChristopheMae.lnk.to/nma",
        id: "christophe-mae-tournee-francophone",
      },
    ],
    picture: "/assets/img/christophemae-desktop.png",
    pictureMobile: "/assets/img/christophemae-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/christophemae",
        points: 20,
      },
      {
        social: "clip",
        twitterText:
          "Découvrez l'album « C'est drôle la vie » de Christophe Maé",
        text: "Partage l'album « C'est drôle la vie » sur ton réseau social favori",
        url: "ChristopheMae.lnk.to/cdlv",
        points: 20,
      },
    ],
  },
  edsheeran: {
    name: "Ed Sheeran",
    url: "edsheeran",
    formID: "38821",
    startDate: "",
    pathName: "edsheeran",
    dota: 'une carte dédicacée ainsi qu\'un sweat, un t-shirt et une gourde "Subtract" !',
    dotaTwi:
      'une carte dédicacée ainsi qu\'un sweat, un t-shirt et une gourde "Subtract" !',
    categories: [
      {
        name: "Artiste Masculin International de l'Année",
        link: "https://WEA.lnk.to/ESNMA",
        id: "ed-sheeran-artiste-masculin-international",
      },
    ],
    picture: "/assets/img/edsheeran-desktop.png",
    pictureMobile: "/assets/img/edsheeran-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/edsheeran",
        points: 20,
      },
      {
        social: "clip",
        twitterText: "Découvrez l'album « - (Deluxe) » de Ed Sheeran",
        text: "Partage l'album « - (Deluxe) » sur ton réseau social favori",
        url: "https://WMF.lnk.to/EdSheeranSubtract",
        points: 20,
      },
    ],
  },
  dualipa: {
    name: "Dua Lipa",
    url: "dualipa",
    formID: "38820",
    startDate: "",
    pathName: "dualipa",
    dota: "un vinyle de l'album \"Barbie The Album\" ainsi qu'un DVD du film !",
    dotaTwi:
      "un vinyle de l'album \"Barbie The Album\" ainsi qu'un DVD du film !",
    categories: [
      {
        name: "Chanson Internationale de l'Année",
        link: "https://WEA.lnk.to/DuaLipaNMA",
        id: "dua-lipa-chanson-internationale",
      },
    ],
    picture: "/assets/img/dualipa-desktop.png",
    pictureMobile: "/assets/img/dualipa-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/dualipa",
        points: 20,
      },
      {
        social: "clip",
        twitterText:
          "Découvrez l'album « Barbie The Album » contenant le titre « Dance The Night » de Dua Lipa",
        text: "Partage l'album « Barbie The Album » sur ton réseau social favori",
        url: "https://WEA.lnk.to/BarbieTheAlbum",
        points: 20,
      },
    ],
  },
  santa: {
    name: "Santa",
    url: "santa",
    formID: "38819",
    startDate: "",
    pathName: "santa",
    dota: "une rencontre avec elle !",
    dotaTwi: "une rencontre avec elle !",
    categories: [
      {
        name: "Artiste Féminine Francophone de l'Année",
        link: "https://Santa.lnk.to/NMA",
        id: "santa-artiste-feminine-francophone",
      },
    ],
    picture: "/assets/img/santa-desktop.png",
    pictureMobile: "/assets/img/santa-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/santa",
        points: 20,
      },
      {
        social: "clip",
        twitterText: "Découvrez le titre « Popcorn Salé » de Santa",
        text: "Partage le titre « Popcorn Salé » sur ton réseau social favori",
        url: "https://Santa.lnk.to/Popcorn",
        points: 20,
      },
    ],
  },
  trinix: {
    name: "Trinix",
    url: "trinix",
    formID: "38824",
    startDate: "",
    pathName: "trinix",
    dota: "2 places pour leur concert pour le 23 Mars à l'Olympia",
    dotaTwi: "2 places pour leur concert pour le 23 Mars à l'Olympia",
    categories: [
      {
        name: "Groupe/Duo Francophone de l'année",
        link: "https://WEA.lnk.to/TrinixNMA",
        id: "trinix-groupe-duo-francophone",
      },
    ],
    picture: "/assets/img/trinix-desktop.png",
    pictureMobile: "/assets/img/trinix-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/trinix",
        points: 20,
      },
      {
        social: "clip",
        twitterText: "Découvrez le titre « Emorio » de Trinix et Fafá de Belém",
        text: "Partage le titre « Emorio » sur ton réseau social favori",
        url: "https://trinix.lnk.to/emorio",
        points: 20,
      },
    ],
  },
  bensonboone: {
    name: "Benson Boone",
    url: "bensonboone",
    formID: "38822",
    startDate: "",
    pathName: "bensonboone",
    dota: "un message personnalisé de sa part !",
    dotaTwi: "un message personnalisé de sa part !",
    categories: [
      {
        name: "Collaboration Francophone de l'année",
        link: "https://WEA.lnk.to/BensonBooneNMA",
        id: "collaboration-francophone-de-l-annee",
      },
    ],
    picture: "/assets/img/bensonboone-desktop.png",
    pictureMobile: "/assets/img/bensonboone-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/bensonboone",
        points: 20,
      },
      {
        social: "clip",
        twitterText: "Découvrez l'EP « Pulse » de Benson Boone",
        text: "Partage l'EP « Pulse » sur ton réseau social favori",
        url: "https://WEA.lnk.to/BensonBoonePulse",
        points: 20,
      },
    ],
  },
  jasonderulo: {
    name: "Jason Derulo",
    url: "jasonderulo",
    formID: "38823",
    startDate: "",
    pathName: "jasonderulo",
    dota: "2 places pour son concert le 27 février au Zénith de Paris",
    dotaTwi: "2 places pour son concert le 27 février au Zénith de Paris",
    categories: [
      {
        name: "Collaboration Internationale de l'année",
        link: "https://WEA.lnk.to/JDNMACollab",
        id: "jason-derulo-collaboration-internationale-de-l-annee",
      },
    ],
    picture: "/assets/img/jasonderulo-desktop.png",
    pictureMobile: "/assets/img/jasonderulo-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/jasonderulo",
        points: 20,
      },
      {
        social: "clip",
        twitterText:
          "Découvrez le titre « Hands On Me » de Jason Derulo en collaboration avec Meghan Trainor",
        text: "Partage le titre « Hands On Me » sur ton réseau social favori",
        url: "https://WEA.lnk.to/handsonmejasonderulo131023",
        points: 20,
      },
    ],
  },
  philippinelavrey: {
    name: "Philippine Lavrey",
    url: "philippinelavrey",
    formID: "39006",
    startDate: "",
    pathName: "philippinelavrey",
    dota: "un disque de platine personnalisé",
    dotaTwi: "un disque de platine personnalisé",
    categories: [
      {
        name: "Collaboration Francophone de l'année",
        link: "https://philippinelavrey.lnk.to/NMA",
        id: "philippine-lavrey-collaboration-francophone-de-l-annee",
      },
    ],
    picture: "/assets/img/philippinelavrey-desktop.png",
    pictureMobile: "/assets/img/philippinelavrey-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/philippinelavrey",
        points: 20,
      },
      {
        social: "clip",
        twitterText:
          "Découvrez le titre « In The Starts » de Benson Boone en collaboration avec Philippine Lavrey",
        text: "Partage le titre « In The Starts » sur ton réseau social favori",
        url: "https://philippinelavrey.lnk.to/ITSFrench",
        points: 20,
      },
    ],
  },
};

export default artistsData