const users = [
  {
    biancacosta: {
      inscrit: false,
      id: "biancacosta",
      name: "Bianca Costa",
      totalPoints: 0,
      votes: [
        {
          id: "bianca-costa-revelation-de-l-annee",
          dates: [],
          points: 0,
        },
      ],
      activations: {
        webapp: {
          points: 0,
          actions: [],
          isDone: false,
        },
        clip: {
          points: 0,
          actions: [],
          isDone: false,
        },
      },
    },
    christophemae: {
      inscrit: false,
      id: "christophemae",
      name: "Christophe Maé",
      totalPoints: 0,
      votes: [
        {
          id: "christophe-mae-tournee-francophone",
          dates: [],
          points: 0,
        },
      ],
      activations: {
        webapp: {
          points: 0,
          actions: [],
          isDone: false,
        },
        clip: {
          points: 0,
          actions: [],
          isDone: false,
        },
      },
    },
    edsheeran: {
      inscrit: false,
      id: "edsheeran",
      name: "Ed Sheeran",
      totalPoints: 0,
      votes: [
        {
          id: "ed-sheeran-artiste-masculin-international",
          dates: [],
          points: 0,
        },
      ],
      activations: {
        webapp: {
          points: 0,
          actions: [],
          isDone: false,
        },
        clip: {
          points: 0,
          actions: [],
          isDone: false,
        },
      },
    },
    dualipa: {
      inscrit: false,
      id: "dualipa",
      name: "Dua Lipa",
      totalPoints: 0,
      votes: [
        {
          id: "dua-lipa-chanson-internationale",
          dates: [],
          points: 0,
        },
      ],
      activations: {
        webapp: {
          points: 0,
          actions: [],
          isDone: false,
        },
        clip: {
          points: 0,
          actions: [],
          isDone: false,
        },
      },
    },
    santa: {
      inscrit: false,
      id: "santa",
      name: "Santa",
      totalPoints: 0,
      votes: [
        {
          id: "santa-artiste-feminine-francophone",
          dates: [],
          points: 0,
        },
      ],
      activations: {
        webapp: {
          points: 0,
          actions: [],
          isDone: false,
        },
        clip: {
          points: 0,
          actions: [],
          isDone: false,
        },
      },
    },
    trinix: {
      inscrit: false,
      id: "trinix",
      name: "Trinix",
      totalPoints: 0,
      votes: [
        {
          id: "trinix-groupe-duo-francophone",
          dates: [],
          points: 0,
        },
      ],
      activations: {
        webapp: {
          points: 0,
          actions: [],
          isDone: false,
        },
        clip: {
          points: 0,
          actions: [],
          isDone: false,
        },
      },
    },
    bensonboone: {
      inscrit: false,
      id: "bensonboone",
      name: "Benson Boone",
      totalPoints: 0,
      votes: [
        {
          id: "collaboration-francophone-de-l-annee",
          dates: [],
          points: 0,
        },
      ],
      activations: {
        webapp: {
          points: 0,
          actions: [],
          isDone: false,
        },
        clip: {
          points: 0,
          actions: [],
          isDone: false,
        },
      },
    },
    jasonderulo: {
      inscrit: false,
      id: "jasonderulo",
      name: "Jason Derulo",
      totalPoints: 0,
      votes: [
        {
          id: "jason-derulo-collaboration-internationale-de-l-annee",
          dates: [],
          points: 0,
        },
      ],
      activations: {
        webapp: {
          points: 0,
          actions: [],
          isDone: false,
        },
        clip: {
          points: 0,
          actions: [],
          isDone: false,
        },
      },
    },
    philippinelavrey: {
      inscrit: false,
      id: "philippinelavrey",
      name: "Philippine Lavrey",
      totalPoints: 0,
      votes: [
        {
          id: "philippine-lavrey-collaboration-francophone-de-l-annee",
          dates: [],
          points: 0,
        },
      ],
      activations: {
        webapp: {
          points: 0,
          actions: [],
          isDone: false,
        },
        clip: {
          points: 0,
          actions: [],
          isDone: false,
        },
      },
    },
  },
];

export default users