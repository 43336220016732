import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import LogoNMA from '../../assets/img/logoNMA.svg'
import artistsData from '../../data/artistsData'
import '../Artist/index.scss'
import Inscription from '../Inscription'
import Footer from '../../components/footer'

const UnloggedArtist = ({ setIsSubscribed, getUserData }) => {
    const location = useLocation()
    const [artist, setArtist] = useState(artistsData[location.pathname.slice(1)])
    const [isOpenInscription, setIsOpenInscription] = useState(false)
    const [isConnectPage, setIsConnectPage] = useState(false)

    useEffect(() => {
        if (artist?.name) {
            window.digitalData={									
                settings:{								
                    reportSuites:"wmg,wmgfr"							
                },								
                page:{								
                    pageInfo:{							
                        pageName:`Warner Music France:NRJ Awards 2023 Vote:Landing:${artist.name}:Timeline`,						
                        server:"Warner Music France:Site",						
                        platform:"Static HTML",						
                        devTeam:"Firepit"						
                    },							
                    category:{							
                        primaryCategory:"Warner Music France:Landing Page",						
                        pageType:"Landing Page:Vote Redirect"						
                    }							
                },								
                content:{								
                    artist:"Warner Music France",							
                    label:"Warner Music International",							
                    sublabel:"Warner Music France"							
                }								
            }									
              
              document.dispatchEvent(new CustomEvent('SPAPageTriggered'));		
        }
    }, [artist?.name])

    return (
        <>
            {isOpenInscription && <Inscription isConnectPage={isConnectPage} getUserData={getUserData} setIsSubscribed={setIsSubscribed} setIsOpenInscription={setIsOpenInscription} artist={artist} />}
            <section className='unloggedArtistPage'>
                <div className="leftPartArtist">
                    <h1>{artist?.name}</h1>
                    <picture>
                            <source srcSet={artist?.picture} media="(min-width: 1025px)" />
                            <img src={artist?.pictureMobile} alt="" />
                        </picture>
                        <Link to="/"><img className="logoNMA mobile" src={LogoNMA} alt="" /></Link>
                </div>
                <div className="rightPartArtist">
                    <Link to="/">
                        <img className='logoNMA desktop' src={LogoNMA} alt="" />
                    </Link>

                    <p className='ferme'>Le concours est terminé, les gagnant.e.s seront recontacté.e.s au plus vite</p>

                    {/* <p className='introduction'>Vote pour {artist?.name} et tente de gagner</p>
                    <p className='lot' dangerouslySetInnerHTML={{ __html: artist.dota }} />
                    <button onClick={() => {setIsOpenInscription(true)}} className='signupCTA'>Inscris-toi</button>
                    <p  onClick={() => {setIsOpenInscription(true); setIsConnectPage(true)}} className='loginCTA'>Déjà inscrit ? Connecte toi</p> */}
                    <Footer disposition='column' />
                </div>
                
            </section>
        </>
    )
}

export default UnloggedArtist