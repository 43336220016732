import React from 'react'
import { useEffect } from 'react'
import useLocalStorage from '../../hooks/useLS'
import LogoWarner from '../../assets/img/logowarner.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import artistsData from '../../data/artistsData';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import './index.scss'
import Footer from '../../components/footer';

let settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [{
        breakpoint: 1024,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        }
    }, {
        breakpoint: 768,
        settings: {
        slidesToShow: 1,
        arrows: false,
        slidesToScroll: 1,
        }
    }]
};

const Artists = () => {
    const [user, setUser] = useLocalStorage("NMA2023user");

    useEffect(() => {
        window.digitalData={							
            settings:{						
                reportSuites:"wmg,wmgfr"					
            },						
            page:{						
                pageInfo:{					
                    pageName:"Warner Music France:NRJ Awards 2023 Vote:Landing",				
                    server:"Warner Music France:Site",				
                    platform:"Static HTML",				
                    devTeam:"Firepit"				
                },					
                category:{					
                    primaryCategory:"Warner Music France:Landing Page",				
                    pageType:"Landing Page:Vote Redirect"				
                }					
            },						
            content:{						
                artist:"Warner Music France",					
                label:"Warner Music International",					
                sublabel:"Warner Music France"					
            }						
        }													
                                        
        document.dispatchEvent(new CustomEvent('SPAPageTriggered'));
    }, [])

    return (
        <>
            <main className="page404">
                <img className="logoWarner" src={LogoWarner} alt="Logo de Warner Music France" />
                <h2>Votez pour vos artistes préférés</h2>
            
                <div className='sliderArtists'>
                    <Slider {...settings}>
                        {Object.values(artistsData).map((artist) => (
                            <div key={artist.id} className="artist">
                                <div className="internalArtist">
                                    <Link to={artist.pathName}>
                                        <img src={artist.pictureMobile} alt="" />
                                    </Link>
                                    <Link to={artist.pathName}>
                                        <p>{artist.name}</p>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default Artists